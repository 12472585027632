import { AMBIENT_COLORS, AMBIENT_EFFECTS } from '@/libs/constants';

const AMBIENT_EFFECT_SPEED = 'Effect speed'; // Menu 11 - submenu 2

export default {
  parameter: {
    title: 'parameter log | parameter logs',

    modeltype_Jewel: {
      version_2: {
        menu_1: {
          title: 'Sunbed',

          // Submenu's
          submenu_1: {
            title: 'Start time',
          },
          submenu_2: {
            title: 'Maximum time',
          },
          submenu_3: {
            title: 'Lamp configuration',
          },
          submenu_5: {
            title: 'Time decoration lights on (HH:MM)',
          },
          submenu_6: {
            title: 'Time decoration lights off (HH:MM)',
          },

          submenu_13: {
            title: 'Clean time (MM:SS)',
          },
        },
        menu_2: {
          title: 'Hours',
        },
        menu_3: {
          title: 'Smartcooling',

          // Submenu's
          submenu_1: {
            title: 'Default temperature sunbed',
          },
          submenu_2: {
            title: 'Max. operation temp. sunbed',
          },

          submenu_5: {
            title: 'Cooling time after session',
          },
          submenu_8: {
            title: 'First increate after',
          },
          submenu_10: {
            title: 'Second increase after',
          },
          submenu_11: {
            title: 'Treshold Booster',
          },
        },
        menu_4: {
          title: 'Body cooler',
        },
        menu_5: {
          title: 'Face cooler',
        },
        menu_6: {
          title: 'Xsens',

          // Submenu's
          submenu_4: {
            title: 'Xsens interval time at standby',
          },
          submenu_5: {
            title: 'Xsens activation time at standby',
          },
        },
        menu_7: {
          title: 'SoundAround',
        },
        menu_8: {
          title: 'Climate Control',

          submenu_1: {
            title: 'Display temperature in Fahrenheit',
          },
          submenu_2: {
            title: 'Temperature at start session',
          },
          submenu_3: {
            title: 'Maximum temperature CC',
          },

          submenu_6: {
            title: 'Delay Climate Control check',
          },
          submenu_7: {
            title: 'Delta-T Climate Control check',
          },
          submenu_8: {
            title: 'Cabin temperature W.A.R.S.',
          },

          submenu_9: {
            title: 'Bench cooler speed at Low',
          },
          submenu_10: {
            title: 'Bench cooler speed at Pause',
          },
          submenu_11: {
            title: 'Bench cooler speed at Session',
          },
          submenu_12: {
            title: 'Bench cooler speed after Session',
          },

          submenu_14: {
            title: 'Delta-T Airco automatically On',
          },

          submenu_16: {
            title: 'Time cabin heating on (HH:MM)',
          },
          submenu_17: {
            title: 'Time cabin heating off (HH:MM)',
          },
        },
        menu_9: {
          title: 'Qsens',

          // Submenu's
          submenu_3: {
            title: 'Delay On and air',
          },
          submenu_4: {
            title: 'Delay air and spray nozzle',
          },
          submenu_5: {
            title: 'Spray time nozzle',
          },
          submenu_6: {
            title: 'Time for cleaning nozzle',
          },
          submenu_7: {
            title: 'Interval time Qsens session',
          },
          submenu_8: {
            title: 'Min. Interval time Qsens session',
          },
        },
        menu_10: {
          title: 'Coin',

          // Submenu's
          submenu_1: {
            title: 'Relay pulse after Start button',
          },
        },
        menu_11: {
          title: 'Ambient FlowLight',

          // Submenu's
          submenu_1: {
            values: AMBIENT_EFFECTS,
          },
          submenu_2: {
            title: AMBIENT_EFFECT_SPEED,
          },
          submenu_4: {
            values: AMBIENT_COLORS,
          },

          submenu_6: {
            title: 'LED bars fade speed',
          },
          submenu_7: {
            title: 'LED bars minimum',
          },
          submenu_8: {
            title: 'LED bars maximum',
          },
          submenu_9: {
            title: 'LED bars delay',
          },
          submenu_10: {
            title: 'LED bars at start session',
          },
          submenu_12: {
            title: 'RED leds Boosters',
          },
          submenu_13: {
            title: 'RED leds face',
          },
        },
        menu_12: {
          title: 'IPc II',

          // Submenu's
          submenu_10: {
            title: 'Fine adjustment canopy blue',
          },
          submenu_11: {
            title: 'Fine adjustment canopy red',
          },
          submenu_12: {
            title: 'Fine adjustment canopy Booster',
          },
          submenu_13: {
            title: 'Fine adjustment bench blue',
          },
          submenu_14: {
            title: 'Fine adjustment bench red',
          },
          submenu_15: {
            title: 'Fine adjustment bench Booster',
          },
          submenu_18: {
            title: 'Booster Red at session',
          },
        },
        menu_13: {
          title: 'Face tanner',

          // Submenu's
          submenu_3: {
            title: 'Fine adjustment UVA LED',
          },
          submenu_4: {
            title: 'Fine adjustment UVB LED',
          },
          submenu_5: {
            title: 'Boost UVA LED',
          },
          submenu_6: {
            title: 'Boost UVB LED',
          },
          submenu_7: {
            title: 'Intensity Red LED at step 1',
          },
          submenu_8: {
            title: 'Intensity Red LED at step 2',
          },
          submenu_9: {
            title: 'Intensity Red LED at step 3',
          },
          submenu_10: {
            title: 'Intensity Red LED at step 4',
          },
          submenu_11: {
            title: 'Red LED after touch',
          },
          submenu_12: {
            title: 'Dim time Red LED after touch',
          },
        },
        menu_14: {
          title: 'Shoulder tanner',
        },
        menu_15: {
          title: 'Power setting',

          // Submenu's
          submenu_3: {
            title: 'Power setting step 1 (Low)',
          },
          submenu_4: {
            title: 'Power setting step 2 (Medium)',
          },
          submenu_5: {
            title: 'Power setting step 3 (Strong)',
          },
        },

        menu_types: {
          // MENU_LANGUAGE
          type_9: {
            value_1: 'German',
            value_2: 'English',
            value_3: 'Swedish',
            value_4: 'Danish',
            value_5: 'Russian',
            value_6: 'Estonian',
            value_7: 'Norwegian',
            value_8: 'Polish',
            value_9: 'Czech',
            value_10: 'Dutch',
            value_11: 'French',
            value_12: 'Greek',
            value_13: 'Italian',
            value_14: 'Latvian',
            value_15: 'Spanish',
            value_16: 'Lithuanian',
            value_17: 'Croatian',
            value_18: 'Portuguese',
            value_19: 'Hungarian',
            value_20: 'Finnish',
          },
        },
      },
    },

    modeltype_LXP16: {
      version_2: {
        menu_1: {
          title: 'Sunbed',

          // Submenu's
          submenu_1: {
            title: 'Start time',
          },
          submenu_2: {
            title: 'Maximum time',
          },
          submenu_3: {
            title: 'Lamp configuration',
          },
          submenu_4: {
            title: 'Power face tanner',
          },
          submenu_5: {
            title: 'Boost face tanner',
          },

          submenu_7: {
            title: 'Time decoration lights on (HH:MM)',
          },
          submenu_8: {
            title: 'Time decoration lights off (HH:MM)',
          },

          submenu_15: {
            title: 'Clean time (MM:SS)',
          },
        },
        menu_2: {
          title: 'Hours',
        },
        menu_3: {
          title: 'Smart cooling',

          // Submenu's
          submenu_1: {
            title: 'Default temperature sunbed',
          },
          submenu_2: {
            title: 'Max. operation temp. sunbed',
          },

          submenu_5: {
            title: 'Cooling time after session',
          },
          submenu_6: {
            title: 'Cooling delay face tanner',
          },
        },
        menu_4: {
          title: 'Body cooler',
        },
        menu_5: {
          title: 'Face cooler',
        },
        menu_6: {
          title: 'Xsens',

          // Submenu's
          submenu_5: {
            title: 'Xsens interval time at standby',
          },
          submenu_6: {
            title: 'Xsens activation time at standby',
          },
        },
        menu_7: {
          title: 'SoundAround',
        },
        menu_8: {
          title: 'Climate control',

          submenu_1: {
            title: 'Display temperature in Fahrenheit',
          },
          submenu_2: {
            title: 'Temperature at start session',
          },
          submenu_3: {
            title: 'Maximum temperature CC',
          },

          submenu_6: {
            title: 'Delay Climate Control check',
          },
          submenu_7: {
            title: 'Delta-T Climate Control check',
          },
          submenu_8: {
            title: 'Cabin temperature W.A.R.S.',
          },

          submenu_9: {
            title: 'Bench cooler speed at Low',
          },
          submenu_10: {
            title: 'Bench cooler speed at Pause',
          },
          submenu_11: {
            title: 'Bench cooler speed at Session',
          },
        },
        menu_9: {
          title: 'Qsens',

          // Submenu's
          submenu_4: {
            title: 'Delay On and air',
          },
          submenu_5: {
            title: 'Delay air and spray nozzle',
          },
          submenu_6: {
            title: 'Delay nozzle - nozzle 2',
          },
          submenu_7: {
            title: 'Spray time nozzle',
          },
          submenu_8: {
            title: 'Spray time nozzle 2',
          },
          submenu_9: {
            title: 'Time for cleaning nozzle',
          },
          submenu_10: {
            title: 'Interval time Qsens session',
          },
          submenu_11: {
            title: 'Min. Interval time Qsens session',
          },
        },
        menu_10: {
          title: 'Coin',

          // Submenu's
          submenu_1: {
            title: 'Relay pulse after Start button',
          },
        },
        menu_11: {
          title: 'Ambient FlowLight',

          // Submenu's
          submenu_1: {
            values: AMBIENT_EFFECTS,
          },
          submenu_2: {
            title: AMBIENT_EFFECT_SPEED,
          },
          submenu_4: {
            values: AMBIENT_COLORS,
          },
        },
        menu_12: {
          title: 'IPc II',

          // Submenu's
          submenu_9: {
            title: 'Fine adjustment canopy blue',
          },
          submenu_10: {
            title: 'Fine adjustment canopy red',
          },
          submenu_11: {
            title: 'Fine adjustment bench blue',
          },
          submenu_12: {
            title: 'Fine adjustment bench red',
          },
          submenu_13: {
            title: 'Fine adjustment face tanner',
          },
        },
        menu_13: {
          title: 'Collagen',

          // Submenu's
          submenu_2: {
            title: 'Intensity Red LED at step 1',
          },
          submenu_3: {
            title: 'Intensity Red LED at step 2',
          },
          submenu_4: {
            title: 'Intensity Red LED at step 3',
          },
          submenu_5: {
            title: 'Intensity Red LED at step 4',
          },
          submenu_6: {
            title: 'Fine adjustment UVA LED',
          },
          submenu_7: {
            title: 'Boost UVA LED',
          },

          submenu_9: {
            title: 'Red LED after touch',
          },
          submenu_10: {
            title: 'Time Red LED after touch',
          },
        },
        menu_14: {
          title: 'Welltherm',

          // Submenu's
          submenu_2: {
            title: 'Max. temp. shoulder tanner',
          },
        },
        menu_15: {
          title: 'Power setting',
        },

        menu_types: {
          // MENU_LANGUAGE
          type_9: {
            value_1: 'German',
            value_2: 'English',
            value_3: 'Swedish',
            value_4: 'Danish',
            value_5: 'Russian',
            value_6: 'Estonian',
            value_7: 'Norwegian',
            value_8: 'Polish',
            value_9: 'Czech',
            value_10: 'Dutch',
            value_11: 'French',
            value_12: 'Greek',
            value_13: 'Italian',
            value_14: 'Latvian',
            value_15: 'Spanish',
            value_16: 'Lithuanian',
            value_17: 'Croatian',
            value_18: 'Portuguese',
            value_19: 'Hungarian',
            value_20: 'Finnish',
          },
        },
      },
    },

    modeltype_X5X7II: {
      version_2: {
        menu_1: {
          title: 'Sunbed',

          // Submenu's
          submenu_1: {
            title: 'Start time',
          },
          submenu_2: {
            title: 'Maximum time',
          },

          submenu_7: {
            title: 'Time decoration lights on (HH:MM)',
          },
          submenu_8: {
            title: 'Time decoration lights off (HH:MM)',
          },

          submenu_15: {
            title: 'Clean time (MM:SS)',
          },
        },
        menu_2: {
          title: 'Hours',
        },
        menu_3: {
          title: 'Smart cooling',

          // Submenu's
          submenu_1: {
            title: 'Bench temperature',
          },
          submenu_2: {
            title: 'Maximum temperature bench',
          },

          submenu_4: {
            title: 'Canopy temperature',
          },
          submenu_5: {
            title: 'Maximum temperature canopy',
          },

          submenu_10: {
            title: 'Cooling time after session',
          },
        },
        menu_4: {
          title: 'Body cooler',
        },
        menu_5: {
          title: 'Face cooler',
        },
        menu_6: {
          title: 'Xsens',

          // Submenu's
          submenu_5: {
            title: 'Xsens interval time at standby',
          },
          submenu_6: {
            title: 'Xsens activation time at standby',
          },
        },
        menu_7: {
          title: 'SoundAround',
        },
        menu_8: {
          title: 'Air conditioning',
        },
        menu_9: {
          title: 'Qsens',

          // Submenu's
          submenu_4: {
            title: 'Delay On and air',
          },
          submenu_5: {
            title: 'Delay air and spray nozzle',
          },
          submenu_6: {
            title: 'Delay nozzle - nozzle 2',
          },
          submenu_7: {
            title: 'Spray time nozzle',
          },
          submenu_8: {
            title: 'Spray time nozzle 2',
          },
          submenu_9: {
            title: 'Time for cleaning nozzle',
          },
          submenu_10: {
            title: 'Interval time Qsens session',
          },
          submenu_11: {
            title: 'Min. Interval time Qsens session',
          },
        },
        menu_10: {
          title: 'Coin',

          // Submenu's
          submenu_1: {
            title: 'Relay pulse after Start button',
          },
        },
        menu_11: {
          title: 'Ambient FlowLight',

          // Submenu's
          submenu_1: {
            values: AMBIENT_EFFECTS,
          },
          submenu_2: {
            title: AMBIENT_EFFECT_SPEED,
          },
          submenu_4: {
            values: AMBIENT_COLORS,
          },
        },
        menu_12: {
          title: 'IPc II',

          // Submenu's
          submenu_9: {
            title: 'Fine adjustment canopy blue',
          },
          submenu_10: {
            title: 'Fine adjustment canopy red',
          },
          submenu_11: {
            title: 'Fine adjustment bench blue',
          },
          submenu_12: {
            title: 'Fine adjustment bench red',
          },
          submenu_13: {
            title: 'Fine adjustment face tanner',
          },
        },
        menu_13: {
          title: 'Collagen',
        },
        menu_14: {
          title: 'Welltherm',

          // Submenu's
          submenu_2: {
            title: 'Maximum Welltherm temperature',
          },
        },
        menu_15: {
          title: 'Power setting',
        },

        menu_types: {
          // MENU_UNIT_TYPE
          type_3: {
            value_0: 'X-SLi',
            value_1: 'X-SPr low',
            value_2: 'X-SPr high',
            value_3: 'X-XL/XLc',
          },

          // MENU_LANGUAGE
          type_9: {
            value_1: 'German',
            value_2: 'English',
            value_3: 'Swedish',
            value_4: 'Danish',
            value_5: 'Russian',
            value_6: 'Estonian',
            value_7: 'Norwegian',
            value_8: 'Polish',
            value_9: 'Czech',
            value_10: 'Dutch',
            value_11: 'French',
            value_12: 'Greek',
            value_13: 'Italian',
            value_14: 'Latvian',
            value_15: 'Spanish',
            value_16: 'Lithuanian',
            value_17: 'Croatian',
            value_18: 'Portuguese',
            value_19: 'Hungarian',
            value_20: 'Finnish',
          },
        },
      },
    },

    modeltype_V6V8: {
      version_2: {
        menu_1: {
          title: 'Sunbed',

          // Submenu's
          submenu_1: {
            title: 'Start time',
          },
          submenu_2: {
            title: 'Maximum time',
          },

          submenu_5: {
            title: 'Time decoration lights on (HH:MM)',
          },
          submenu_6: {
            title: 'Time decoration lights off (HH:MM)',
          },

          submenu_13: {
            title: 'Clean time (MM:SS)',
          },
        },
        menu_2: {
          title: 'Hours',
        },
        menu_3: {
          title: 'Body cooler',
        },
        menu_4: {
          title: 'Xsens',

          // Submenu's
          submenu_5: {
            title: 'Xsens Interval time at standby',
          },
          submenu_6: {
            title: 'Xsens activation time at standby',
          },
        },
        menu_5: {
          title: 'SoundAround',
        },
        menu_6: {
          title: 'Qsens',

          // Submenu's
          submenu_4: {
            title: 'Delay On and air',
          },
          submenu_5: {
            title: 'Delay air and spray nozzle',
          },
          submenu_6: {
            title: 'Delay nozzle - nozzle 2',
          },
          submenu_7: {
            title: 'Spray time nozzle',
          },
          submenu_8: {
            title: 'Spray time nozzle 2',
          },
          submenu_9: {
            title: 'Time for cleaning nozzle',
          },
          submenu_10: {
            title: 'Interval time Qsens session',
          },
          submenu_11: {
            title: 'Min. Interval time Qsens session',
          },
        },
        menu_7: {
          title: 'Coin',

          // Submenu's
          submenu_1: {
            title: 'Relay pulse after Start button',
          },
        },
        menu_8: {
          title: 'Ambient FlowLight',

          // Submenu's
          submenu_1: {
            values: AMBIENT_EFFECTS,
          },
          submenu_2: {
            title: AMBIENT_EFFECT_SPEED,
          },
          submenu_4: {
            values: AMBIENT_COLORS,
          },
        },
        menu_9: {
          title: 'IPc II',

          // Submenu's
          submenu_7: {
            title: 'Fine adjustment blue',
          },
          submenu_8: {
            title: 'Fine adjustment red',
          },
          submenu_9: {
            title: 'Fine adjustment green',
          },
        },
        menu_10: {
          title: 'Power setting',
        },

        menu_types: {
          // MENU_LANGUAGE
          type_9: {
            value_1: 'German',
            value_2: 'English',
            value_3: 'Swedish',
            value_4: 'Danish',
            value_5: 'Russian',
            value_6: 'Estonian',
            value_7: 'Norwegian',
            value_8: 'Polish',
            value_9: 'Czech',
            value_10: 'Dutch',
            value_11: 'French',
            value_12: 'Greek',
            value_13: 'Italian',
            value_14: 'Latvian',
            value_15: 'Spanish',
            value_16: 'Lithuanian',
            value_17: 'Croatian',
            value_18: 'Portuguese',
            value_19: 'Hungarian',
            value_20: 'Finnish',
          },
        },
      },
    },
  },

  boot: {
    title: 'boot log | boot logs',
    modules: {
      display: {
        name: 'Controller',
      },
      displayFiles: {
        name: 'Controller files',
      },
      displayTouch: {
        name: 'Controller touch',
      },
      switchboard: {
        name: 'Main board',
      },

      fanModule1: {
        name: 'Smart cooling',
      },
      fanModule2: {
        name: 'Smart cooling 2',
      },

      qsensModule: {
        name: 'Qsens',

        // Equipment specific names
        equipment: {
          luxura_jewel: {
            name: 'Sens',
          },
        },
      },
      xsensModule: {
        name: 'Xsens',

        // Equipment specific names
        equipment: {
          luxura_jewel: {
            name: 'Sens 2',
          },
        },
      },

      climateControl: {
        name: 'Climate control',
      },
      aircoModule: {
        name: 'Climate control (air conditioning)',

        // Equipment specific names
        equipment: {
          luxura_x5: {
            name: 'Air conditioning',
          },
          luxura_x7: {
            name: 'Air conditioning',
          },
        },
      },
      heatModule: {
        name: 'Shoulder tanner',
      },

      audioModule: {
        name: 'SoundAround Plus',
      },
      btModule: {
        name: 'Bluetooth',
      },

      rgbModule: {
        name: 'Ambient FlowLight',
      },
      gbFanModule: {
        name: 'Face tanner cooling',
      },
      rgbModule3: {
        name: 'Ambient FlowLight #3',
      },
      rgbModule4: {
        name: 'Ambient FlowLight #4',
      },

      ipcModule1: {
        name: 'IP Control II (Bench)',
      },
      ipcModule2: {
        name: 'IP Control II (Canopy)',
      },
      ipcModule3: {
        name: 'IP Control II (#3)',
      },
      ipcModule4: {
        name: 'IP Control II (#4)',
      },

      uvLed20Module1: {
        name: 'UV LED tanning booster (20-1)',
      },
      uvLed20Module2: {
        name: 'UV LED tanning booster (20-2)',
      },
      uvLed30Module1: {
        name: 'UV LED tanning booster (30-1)',
      },
      uvLed30Module2: {
        name: 'UV LED tanning booster (30-2)',
      },
      uvLed30V2Module1: {
        name: 'UV LED tanning booster (30V2-1)',
      },
      uvLed30V2Module2: {
        name: 'UV LED tanning booster (30V2-2)',
      },

      mainIc: {
        name: 'Main board',
      },
      sensIc: {
        name: 'Main board sens',
      },

      driver1Module1: {
        name: 'Face tanner 1',
      },
      driver1Module2: {
        name: 'Face tanner 2',
      },
      driver1Module3: {
        name: 'Face tanner 3',
      },
      driver1Module4: {
        name: 'Face tanner 4',
      },

      myLuxuraCl: {
        name: 'MyLuxura Module',
      },
    },
  },

  session: {
    title: 'session log | session logs',
  },

  error: {
    title: 'error log | error logs',
    errors: {
      E0: {
        description: 'Software Error',
        cause: 'Something went wrong with the sunbed’s control panel, it looks like software is not functioning properly.',
        action: 'Please make sure all correct software versions are installed and sunbed’s control panel SD Card is working properly and inserted (correctly).',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360018005897';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360020568997';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360020568877';
            }

            default: {
              return '';
            }
          }
        },
      },
      E1: {
        description: 'Internal Timer has a defect',
        cause: 'Sunbed’s control panel or main circuit board is defective, which causes internal timer / session time to malfunction.',
        action: 'Please replace part in concern.',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360018157098';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360020710598';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360020568897';
            }

            default: {
              return '';
            }
          }
        },
      },
      E2: {
        description: 'Wind Switch Lower compartment fault',
        cause: 'Wind switch on main fan does not switch on. Please make sure main fan and wind switch are functioning properly.',
        action: 'Please make sure main fan and wind switch are functioning properly.',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360018184818';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360020710618';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E3: {
        description: 'Communication error between relay and switch part',
        cause: 'Main Circuit Board or Sunbed’s control panel is defective.',
        action: 'Please replace part in concern.',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360018047877';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360020569057';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360020710418';
            }

            default: {
              return '';
            }
          }
        },
      },
      E4: {
        description: 'Communication error switch section',
        cause: 'Main Circuit Board is defective.',
        action: 'Please replace part in concern.',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360018048037';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return '';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E5: {
        description: 'Maximum temperature Welltherm',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return '';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return '';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E6: {
        description: 'Unknown phase',
        cause: 'Please make sure switch on main circuit board is put in the right position. Main Circuit board might be defective.',
        action: 'Please make sure switch on main circuit board is put in the right position.',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360018186298';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360020710638';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360020568917';
            }

            default: {
              return '';
            }
          }
        },
      },
      E7: {
        description: 'Overrule switch section defective',
        cause: 'Sunbed’s main circuit board is defective.',
        action: 'Please replace part in concern.',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360018186398';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360020710658';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360020568937';
            }

            default: {
              return '';
            }
          }
        },
      },
      E9: {
        description: 'Maximum temperature bench',
        action: 'Please make sure main fan is functioning properly.',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360018186438';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360020710678';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E10: {
        description: 'Maximum temperature canopy',
        action: 'Please make sure main fan is functioning properly.',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360018186458';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360020710698';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E11: {
        description: 'Communication fault controller board ID11',
        cause: 'Circuit Board (29726) Xpr04 is defective.',
        action: 'Please replace part in concern.',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360018186478';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360020569177';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360020568957';
            }

            default: {
              return '';
            }
          }
        },
      },
      E12: {
        description: 'Communication fault controller board ID12',
        cause: 'Circuit Board (29726) Xpr05 is defective.',
        action: 'Please replace part in concern.',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360018188078';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return '';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E17: {
        description: 'Microprocessor I/O section fault',
        cause: 'Sunbed’s control panel is defective.',
        action: 'Please replace part in concern.',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360018052697';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return '';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360020710438';
            }

            default: {
              return '';
            }
          }
        },
      },
      E18: {
        description: 'Communication error with temperature sensor bench',
        cause: 'Communication failure with temperature sensor in bench',
        action: 'Please make sure wiring, circuit board Xpr04 (29726), and temperature sensor are functioning properly.',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360018191998';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360020569197';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E18_40: {
        description: 'Communication fault BosBooster 1 NTC bench',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_jewel': {
              return '';
            }

            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return '';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return '';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E18_80: {
        description: 'Communication fault BosBooster 4 NTC bench',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_jewel': {
              return '';
            }

            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return '';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return '';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E19: {
        description: 'Communication error with temperature sensor canopy',
        cause: 'Communication failure with temperature sensor in canopy.',
        action: 'Please make sure wiring, circuit board Xpr04 (29726), and temperature sensor are functioning properly.',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360018056297';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360020569217';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E19_10: {
        description: 'Communication fault BosBooster 12 NTC canopy',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_jewel': {
              return '';
            }

            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return '';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return '';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E19_20: {
        description: 'Communication fault BosBooster 13 NTC canopy',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_jewel': {
              return '';
            }

            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return '';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return '';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E20: {
        description: 'Climate control reservoir full',
        action: 'Please empty Climate Control reservoir.',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360018056337';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360020710718';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E21: {
        description: 'Climate control needs cleaning',
        action: 'Please clean Climate Control as described in your manual.',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360018192478';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360020569237';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E22: {
        description: 'Change lamps',
        cause: 'Maximum lamp lifetime is met. Maximum lifetime can be reviewed and set in parameter menu 2 ‘Hours’.',
        action: 'Please replace lamps.',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360018192498';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360020710738';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360020568977';
            }

            default: {
              return '';
            }
          }
        },
      },
      E22_2: {
        description: 'Change lampset (1)',
        cause: 'Maximum lampset 1 lifetime is met. Maximum lifetime can be reviewed and set in parameter menu 2 ‘Hours’.',
        action: 'Please replace lampset 1.',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360018056757';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return '';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E22_3: {
        description: 'Change lampset (2)',
        cause: 'Maximum lampset 2 lifetime is met. Maximum lifetime can be reviewed and set in parameter menu 2 ‘Hours’.',
        action: 'Please replace lampset 2.',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360018056777';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return '';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E22_4: {
        description: 'Change facial tanner lamps',
        cause: 'Maximum facial lamps lifetime is met. Maximum lifetime can be reviewed and set in parameter menu 2 ‘Hours’.',
        action: 'Please replace facial lamps.',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360018056817';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return '';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E22_5: {
        description: 'Change shoulder tanner lamps',
        cause: 'Maximum should lamps lifetime is met. Maximum lifetime can be reviewed and set in parameter menu 2 ‘Hours’.',
        action: 'Please replace shoulder lamps.',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360018193338';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return '';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E22_6: {
        description: "Change LED's UV/Red",
        cause: 'Maximum LEDs lifetime is met. Maximum lifetime can be reviewed and set in parameter menu 2 ‘Hours’.',
        action: 'Please replace LEDs.',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360018193378';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return '';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E22_7: {
        description: 'Change acrylic bench',
        cause: 'Maximum bench acrylic lifetime is met. Maximum lifetime can be reviewed and set in parameter menu 2 ‘Hours’.',
        action: 'Please replace bench acrylic.',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360018056877';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return '';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E22_8: {
        description: 'Change acrylic bench Climate control',
        cause: 'Maximum climate control acrylic lifetime is met. Maximum lifetime can be reviewed and set in parameter menu 2 ‘Hours’.',
        action: 'Please replace climate control acrylic.',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360018056917';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return '';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E22_9: {
        description: 'Change acrylic back side',
        cause: 'Maximum rear acrylic lifetime is met. Maximum lifetime can be reviewed and set in parameter menu 2 ‘Hours’.',
        action: 'Please replace rear acrylic.',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360018056957';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return '';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E22_10: {
        description: 'Change acrylic canopy',
        cause: 'Maximum canopy acrylic lifetime is met. Maximum lifetime can be reviewed and set in parameter menu 2 ‘Hours’.',
        action: 'Please replace canopy acrylic',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360018193538';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return '';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E24: {
        description: 'Qsens jerrycan level too low',
        action: 'Please replace Qsens jerry-can. If Qsens jerry-can is not empty, make sure Qsens sensor is connected and functioning properly.',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360018192778';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return '';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360020710458';
            }

            default: {
              return '';
            }
          }
        },
      },
      E26: {
        description: 'Clixon climate control fault',
        action: 'Please make sure body fan is functioning properly.',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360018188158';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return '';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E29: {
        description: 'Maximum temperature shoulder tanner',
        action: 'Please make sure main fan is functioning properly. Please make sure fan in sunbed extension is functioning properly. Please check dust filter in sunbed extension.',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360018188178';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return '';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E30: {
        description: 'Filter shoulder tanner defective',
        cause: 'Error could also be caused by defective glass-break-switch.',
        action: 'Please replace UV-Filter of shoulder tanner.',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360018053117';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return '';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E31: {
        description: 'Facial filter defective',
        cause: 'Error could also be caused by defective glass-break-switch.',
        action: 'Please replace UV-Filter of facial tanner.',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360018053257';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return '';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E32: {
        description: 'Climate control no longer cooling',
        action: 'Please make sure Climate Control is functioning properly during a tanning session. Cool air should be coming out of outlet.',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360018192838';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return '';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E34: {
        description: 'Maximum temperature climate control',
        action: 'Please make sure body fan temperature sensor in sunbed’s canopy near bellows are functioning properly.',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360018188878';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return '';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E33: {
        description: 'SD card I/O section is full',
        action: 'Please replace sunbed’s control panel or SD-Card.',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360018056577';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360020710758';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360020710478';
            }

            default: {
              return '';
            }
          }
        },
      },
      E35: {
        description: "Maximum temperature LED's UV/Red",
        action: 'Please make sure main fan is functioning properly.',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360018057437';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return '';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E36: {
        description: 'Reset Received',
      },
      E37: {
        description: 'Lease Time Expired',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return 'https://support.vdlhapro.com/hc/en-150/articles/360018194238';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return '';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E41: {
        description: 'Sens Controller error',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_jewel': {
              return '';
            }

            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return '';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return '';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E42: {
        description: 'Incorrect voltage driver 1 face tanner',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_jewel': {
              return '';
            }

            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return '';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return '';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E42_1: {
        description: 'Power supply wrongly present on driver 1 board 1',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_jewel': {
              return '';
            }

            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return '';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return '';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E43: {
        description: 'No voltage on driver 1 face tanner',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_jewel': {
              return '';
            }

            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return '';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return '';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E43_1: {
        description: 'Power supply not present on driver 1 board 1',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_jewel': {
              return '';
            }

            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return '';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return '';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E44: {
        description: 'Incorrect output current on driver 1 face tanner',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_jewel': {
              return '';
            }

            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return '';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return '';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E44_1: {
        description: 'Wrong output current on driver 1 board 1',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_jewel': {
              return '';
            }

            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return '';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return '';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E45: {
        description: 'Maximum temperature reached on driver 1 face tanner',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_jewel': {
              return '';
            }

            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return '';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return '';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E45_1: {
        description: 'Maximum temperature driver 1 board 1',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_jewel': {
              return '';
            }

            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return '';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return '';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E46: {
        description: 'Communication fault with driver 1 face tanner',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_jewel': {
              return '';
            }

            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return '';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return '';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E46_1: {
        description: 'Communication error with driver 1 board 1',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_jewel': {
              return '';
            }

            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return '';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return '';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E42_2: {
        description: 'Power supply not present on driver 1 board 2',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_jewel': {
              return '';
            }

            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return '';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return '';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E43_2: {
        description: 'Power supply not present on driver 1 board 2',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_jewel': {
              return '';
            }

            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return '';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return '';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E44_2: {
        description: 'Wrong output current on driver 1 board 2',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_jewel': {
              return '';
            }

            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return '';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return '';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E45_2: {
        description: 'Maximum temperature driver 1 board 2',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_jewel': {
              return '';
            }

            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return '';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return '';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E46_2: {
        description: 'Communication error with driver 1 board 2',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_jewel': {
              return '';
            }

            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return '';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return '';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E42_3: {
        description: 'Power supply wrongly present on driver 1 board 3',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_jewel': {
              return '';
            }

            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return '';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return '';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E43_3: {
        description: 'Power supply not present on driver 1 board 3',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_jewel': {
              return '';
            }

            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return '';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return '';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E44_3: {
        description: 'Wrong output current on driver 1 board 3',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_jewel': {
              return '';
            }

            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return '';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return '';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E45_3: {
        description: 'Maximum temperature driver 1 board 3',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_jewel': {
              return '';
            }

            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return '';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return '';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E46_3: {
        description: 'Communication error with driver 1 board 3',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_jewel': {
              return '';
            }

            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return '';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return '';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E42_4: {
        description: 'Power supply wrongly present on driver 1 board 4',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_jewel': {
              return '';
            }

            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return '';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return '';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E43_4: {
        description: 'Power supply not present on driver 1 board 4',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_jewel': {
              return '';
            }

            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return '';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return '';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E44_4: {
        description: 'Wrong output current on driver 1 board 4',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_jewel': {
              return '';
            }

            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return '';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return '';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E45_4: {
        description: 'Maximum temperature driver 1 board 4',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_jewel': {
              return '';
            }

            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return '';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return '';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E46_4: {
        description: 'Communication error with driver 1 board 4',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_jewel': {
              return '';
            }

            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return '';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return '';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
      E62: {
        description: 'Date/time error of RTC on control panel',
        support_url: (ctx) => {
          const equipment = ctx.named('equipment');
          if (!equipment) {
            return '';
          }

          switch (equipment.model) {
            case 'luxura_jewel': {
              return '';
            }

            case 'luxura_vegaz_9200':
            case 'luxura_vegaz_8200': {
              return '';
            }

            case 'luxura_x7':
            case 'luxura_x5': {
              return '';
            }

            case 'luxura_v8':
            case 'luxura_v6': {
              return '';
            }

            default: {
              return '';
            }
          }
        },
      },
    },
  },

  event: {
    title: 'event log | event logs',
  },
};
